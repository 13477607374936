<script setup lang="ts">
import { computed, ref } from 'vue';
import GenericModal from './GenericModal.vue';
import ButtonComponent from '../common/ButtonComponent.vue';
import ConfirmNewPassword from './auth/ConfirmNewPassword.vue';
import { useRouter } from 'vue-router';

interface Props {
  modelValue?: boolean;
  message: string;
  title: string;
}

interface ModalStep extends Props {
  id: number;
  isVisibleButton: boolean;
  textFirstButton: string;
  textSecondButton: string;
  image: string;
  isFromChangePassword: boolean;
  isVisibleImage: boolean;
  isSecondButton?: boolean;
}

const props = defineProps<Props>();
const currentStep = ref(0);
const fromPasswordRef = ref();
const router = useRouter();
const isWelcomePasswordModal = ref(true);

const emit = defineEmits<{
  'update:modelValue': [value: boolean];
}>();

const show = computed({
  get: () => props.modelValue,
  set: (value) => emit('update:modelValue', value),
});

const steps: ModalStep[] = [
  {
    id: 1,
    title: 'Welcome to Legendaryum',
    message:
      'Enjoy amazing games, live unique experiences and win prizes in missions and tournaments. The adventure begins! 🚀',
    isVisibleButton: false,
    textFirstButton: '',
    textSecondButton: 'Next',
    image: '/images/bg-welcome-modal.png',
    isFromChangePassword: false,
    isVisibleImage: false,
  },
  {
    id: 2,
    title: 'Información',
    message:
      'Create your password and activate your Gaming Passport. Earn points, compete in hundreds of games and win great prizes. 🏆',
    isVisibleButton: true,
    textFirstButton: 'Create later',
    textSecondButton: 'Save and continue',
    image: '/images/bg-gammig-password-modal.webp',
    isFromChangePassword: true,
    isVisibleImage: false,
    isSecondButton: true,
  },
  {
    id: 3,
    title: 'Finalizado',
    message:
      'Sign up and earn 1400 Legends Coins 💎 to create your Home, virtual worlds and a Hall of Fame. Live Legendaryum! 🌐✨',
    isVisibleButton: true,
    textFirstButton: 'Create later',
    textSecondButton: 'Create island now',
    image: '/images/bg-rankings-card.webp',
    isFromChangePassword: false,
    isSecondButton: false,
    isVisibleImage: true,
  },
];

const currentTitle = computed(() => steps[currentStep.value].title);
const currentMessage = computed(() => steps[currentStep.value].message);
const currentButton = computed(() => steps[currentStep.value].isVisibleButton);
const currentImage = computed(() => steps[currentStep.value].image);
const currentIsVisibleImage = computed(
  () => steps[currentStep.value].isVisibleImage,
);
const textFirstButton = computed(
  () => steps[currentStep.value].textFirstButton,
);
const currentIsFromChangePassword = computed(
  () => steps[currentStep.value].isFromChangePassword,
);
const textSecondButton = computed(
  () => steps[currentStep.value].textSecondButton,
);

const currentIsSecondButton = computed(
  () => steps[currentStep.value].isSecondButton,
);

const stepColor = ref(
  [1, 2, 3].map((id, index) => ({
    idColor: id,
    colorStep: index === 0 ? '#019E11' : '#BABABA',
  })),
);

function nextStepSecondButton() {
  stepColor.value[currentStep.value + 1].colorStep = '#019E11';
  currentStep.value++;
}

async function triggerNextStep() {
  if (currentIsFromChangePassword.value) {
    if (currentStep.value === 2) {
      nextStepSecondButton();
    }
    await fromPasswordRef.value.triggerSubmit();
  } else {
    if (currentIsSecondButton.value === false) {
      emit('update:modelValue', false);

      await router.push('/user/rooms');
    }
    nextStepSecondButton();
  }
}

async function nextStepFirstButton() {
  if (currentIsSecondButton.value) {
    nextStepSecondButton();
  } else {
    await router.push('/');
    emit('update:modelValue', false);
  }
}
</script>

<template>
  <GenericModal
    v-model="show"
    container-class="bg-primary-gradient max-w-lg flex flex-col item-center"
    :title="currentTitle"
    :close-to-click-outside="false"
    :hide-close-button="false"
    title-class-container="flex flex-col justify-center"
    title-class="font-bold text-2xl"
    :current-color-step="stepColor"
  >
    <template #default>
      <p class="pb-4 text-lg font-normal text-white">{{ currentMessage }}</p>

      <div
        v-if="!currentIsFromChangePassword && !currentIsVisibleImage"
        class="h-[260px] max-w-lg text-base"
      >
        <img
          class="size-full rounded-lg object-cover"
          :src="currentImage"
          alt=""
        />
      </div>

      <div
        v-else-if="currentIsFromChangePassword"
        class="relative flex size-full flex-col items-center justify-center"
      >
        <div
          class="absolute inset-0 size-full rounded-lg bg-cover bg-center"
          :style="{ backgroundImage: `url(${currentImage})` }"
        >
          <div class="absolute inset-0 rounded-lg bg-[#5B1DAA]/90"></div>
        </div>
        <div class="relative z-10 h-[260px] w-[276px] rounded-lg">
          <ConfirmNewPassword
            :isWelcomePasswordModal="isWelcomePasswordModal"
            ref="fromPasswordRef"
            :onNextStepSecondButton="nextStepSecondButton"
          />
        </div>
      </div>

      <div
        v-else
        class="relative flex h-[260px] w-[420px] items-center justify-center overflow-hidden"
      >
        <div class="size-full">
          <img
            :src="currentImage"
            alt=""
            class="absolute inset-0 h-full w-[800px] rounded-lg object-cover"
          />
          <div
            class="absolute inset-0 flex flex-col items-center justify-center rounded-lg bg-[#5B1DAA]/70"
          >
            <img class="w-[100px]" src="/images/bg-money.png" alt="" />
            <p class="text-[22px] font-bold text-white">1400 Legends</p>
          </div>
        </div>
      </div>
    </template>

    <template #footer>
      <div class="flex justify-between gap-6">
        <ButtonComponent
          variant="secondary"
          class="w-full"
          :class="currentButton ? 'block' : 'invisible'"
          height="md"
          width="none"
          font-size="base"
          @click="nextStepFirstButton"
        >
          {{ textFirstButton }}
        </ButtonComponent>

        <ButtonComponent
          variant="secondary"
          class="w-full text-xl"
          height="md"
          width="none"
          font-size="base"
          @click="triggerNextStep"
        >
          {{ textSecondButton }}
        </ButtonComponent>
      </div>
    </template>
  </GenericModal>
</template>

<style scoped>
.title-container {
  display: block !important;
}
</style>
