<script setup lang="ts">
import { rankingService } from '@/services/rankings.service';
import { ref } from 'vue';
import AuthInput from '@/components/auth/AuthInput.vue';
import { handleAxiosError } from '@/utils/error';
import { toast } from '@/utils/toast';

const newPassword = ref('');
const confirmNewPassword = ref('');
const passwordsError = ref(false);
const passwordsErrorMsg = ref('');
const passwordIsChanged = ref(false);
const isLoading = ref(false);
const titleFrom = ref({
  title: 'Create your Gaming Passport',
  titlePassword: 'New password',
  subtTitlePassword: 'Repeat password',
});

interface Props {
  isEdit?: boolean;
  informationEdit?: any;
  modelValue?: boolean;
  onNextStepSecondButton?: () => void;
  isWelcomePasswordModal?: boolean;
}

const props = defineProps<Props>();

const emits = defineEmits(['update:modelValue']);

const clearPasswordErrors = () => {
  passwordsError.value = false;
  passwordsErrorMsg.value = '';
};

const validatePasswords = () => {
  if (newPassword.value !== confirmNewPassword.value) {
    passwordsError.value = true;
    passwordsErrorMsg.value = 'Las contraseñas deben coincidir';
    return false;
  }

  const passwordRegex = /^(?=.*[A-Z])(?=.*\d).{4,}$/;
  if (!passwordRegex.test(newPassword.value)) {
    passwordsError.value = true;
    passwordsErrorMsg.value = 'Mínimo 12 caractéres, 1 mayúscula y 1 dígito';
    return false;
  }
  return true;
};

const changePassword = async () => {
  if (!validatePasswords()) return;

  try {
    isLoading.value = true;
    passwordIsChanged.value = false;
    await rankingService.postChangePasswordRanking(
      newPassword.value,
      confirmNewPassword.value,
    );
    if (props.isWelcomePasswordModal) {
      toast.success({
        title: 'Password changed',
        message: 'Your password has successfully created.',
      });
      props?.onNextStepSecondButton();
      return;
    }

    emits('update:modelValue', false);
    passwordIsChanged.value = true;
    toast.success({
      title: 'Password changed',
      message: 'Your password has successfully created.',
    });

    return;
  } catch (error) {
    const { message } = handleAxiosError(error);
    if (message === 'User already exists') {
      passwordIsChanged.value = true;
    }
    passwordsError.value = true;
    passwordsErrorMsg.value = message;
    toast.error(message);
  } finally {
    isLoading.value = false;
  }
};

const editChangePassword = async () => {
  if (!validatePasswords()) return;

  try {
    isLoading.value = true;
    passwordIsChanged.value = false;
    await rankingService.putChangePasswordRanking(
      newPassword.value,
      confirmNewPassword.value,
    );
    emits('update:modelValue', false);
    passwordIsChanged.value = true;
    toast.success({
      title: 'Password edit changed',
      message: 'Your password has been changed successfully.',
    });
  } catch (error) {
    handleAxiosError(error);
  } finally {
    isLoading.value = false;
  }
};

const onSubmitPassword = async () => {
  if (props.isEdit) {
    await editChangePassword();
  } else {
    await changePassword();
  }
};

defineExpose({ triggerSubmit: onSubmitPassword });
</script>

<template>
  <main class="h-full">
    <div class="flex size-full flex-col">
      <form
        class="flex size-full flex-col justify-between"
        @submit.prevent="onSubmitPassword"
      >
        <div class="flex size-full flex-col justify-center gap-4">
          <div>
            {{ props.isEdit ? props.informationEdit.title : titleFrom.title }}
          </div>
          <div class="flex flex-col gap-4">
            <AuthInput
              v-model="newPassword"
              :error="passwordsError"
              :title="
                props.isEdit
                  ? props.informationEdit.titlePassword
                  : titleFrom.titlePassword
              "
              input-type="password"
              @clear-errors="clearPasswordErrors"
            />
            <AuthInput
              v-model="confirmNewPassword"
              :error="passwordsError"
              :title="
                props.isEdit
                  ? props.informationEdit.subtTitlePassword
                  : titleFrom.subtTitlePassword
              "
              input-type="password"
              :error-message="passwordsErrorMsg"
              @clear-errors="clearPasswordErrors"
            />
          </div>
        </div>
      </form>
    </div>
  </main>
</template>
