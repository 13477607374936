<template>
  <div class="ad-container h-[96px] w-full" :style="containerStyle">
    <ins
      :class="`adsbygoogle ${insClass} text-center w-full h-full`"
      :style="adStyle"
      :data-ad-client="adClient"
      :data-ad-slot="adSlot"
      :data-ad-format="adFormat"
      data-full-width-responsive="true"
    >
    </ins>
  </div>
</template>

<script setup lang="ts">
import { onMounted, computed } from 'vue';

const props = defineProps<{
  insClass?: string;
  adClient: string;
  adSlot: string;
  adFormat?: string;
  width?: string;
  height?: string;
  display?: string;
}>();

const containerStyle = computed(() => ({
  width: props.width || '100%',
  height: props.height || '96px',
  maxWidth: '100%',
  overflow: 'hidden',
}));

const adStyle = computed(() => ({
  display: props.display || 'block',
  width: props.width || '100%',
  height: props.height || '100%',
  maxWidth: props.width || '100%',
}));

onMounted(() => {
  try {
    const adsbygoogle = ((
      window as unknown as { adsbygoogle: Array<Record<string, unknown>> }
    ).adsbygoogle ||= []);
    adsbygoogle.push({});
  } catch (error) {
    console.error('Error loading AdSense:', error);
  }
});
</script>
<style scoped>
.ad-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
