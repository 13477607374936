<script setup lang="ts">
import GenericModal from '@/components/modals/GenericModal.vue';
import { computed, onMounted, ref, watch } from 'vue';
import { truncateHash, formatTimestamp } from '@/utils/format';
import useSocialShare from '@/composables/useSocialShare';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue';
import useWallet from '@/composables/useWallet';
import { useRouter } from 'vue-router';
import ButtonComponent from '@/components/common/ButtonComponent.vue';
import { useWalletStore } from '@/stores/wallet';
import { ContractType, TokenBalance, Transaction } from '@0xsequence/indexer';
import { useAuthStore } from '@/stores';
import { useBalance } from '@/composables/useBalance';
import { tokenParser } from '@/utils/wallet/tokenParser';
import PromoBanner from '../common/PromoBanner.vue';
import { transactionValidate } from '@/utils/wallet/transactionValidate';
useBalance(); // refetch etherBalance

interface Props {
  modelValue: boolean;
}

const emit = defineEmits<{
  'update:modelValue': [value: boolean];
}>();

const showComingSoon = ref(false);
const show = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit('update:modelValue', value);
  },
});
const walletStore = useWalletStore();
const authStore = useAuthStore();

const router = useRouter();
const {
  myWallet,
  coins,
  chains,
  transactionsList,
  mainTokenFormatted,
  tokensList,
  nftList,
  getTokenBalance,
  getTransferBalance,
} = useWallet();
const props = defineProps<Props>();
const menuSection = ref(0);

watch(
  () => walletStore.chainEndpoint,
  async () => {
    authStore.sequenceInit(walletStore.chainEndpoint, true);
    if (walletStore.isIndexerLoading) return;
    await walletStore.getIndexer(true);
    if (!walletStore.walletAddress) return;
    await walletStore.subscribeEvents();
  },
);

async function goToNFT(nft: TokenBalance) {
  await router.push({
    name: 'CurrentNFT',
    query: { tokenID: nft.tokenID, contractAddress: nft.contractAddress },
  });
  show.value = false;
}

async function backToTab() {
  await router.push({ name: 'WalletView' });
  show.value = false;
}

async function goToNFTs() {
  await router.push({ name: 'WalletNFT' });
  show.value = false;
}

async function goToTokens() {
  await router.push({ name: 'WalletToken' });
  show.value = false;
}

async function goToActivity() {
  await router.push({ name: 'WalletActivity' });
  show.value = false;
}

function transactionHandler() {
  menuSection.value = 2;
}

async function goToTransaction(transaction: Partial<Transaction>) {
  await router.push({
    name: 'WalletActivityByTxn',
    params: { txn: transaction.txnHash },
  });
  show.value = false;
}

async function connectWallet() {
  show.value = false;
  await authStore.sequenceLoginWithEmail();
}

onMounted(async () => {
  if (walletStore.isIndexerLoading) return;
  await walletStore.getIndexer();
  await walletStore.subscribeEvents();
});
</script>
<template>
  <GenericModal
    v-model="show"
    screen-position="top-0 right-0"
    :hidden-title="true"
    size="sm"
  >
    <section class="h-[655px] w-[350px]">
      <div class="mb-3 flex items-center justify-end gap-3 p-4">
        <h1 class="text-sm">Select your network</h1>
        <Menu
          as="div"
          class="z-10 flex h-7 rounded-full bg-white pl-2 text-black"
        >
          <MenuButton class="self-center">
            <div class="group flex cursor-pointer items-center gap-1">
              <IconifyIcon
                :icon="walletStore.chainIcon"
                class="rounded-full bg-gray-100"
              />
              <h1>{{ walletStore.chainName }}</h1>

              <IconifyIcon width="1.5em" icon="mdi:chevron-down" />
            </div>
          </MenuButton>
          <Transition
            enter-active-class="transition duration-100 ease-out"
            enter-from-class="transform scale-95 opacity-0"
            enter-to-class="transform scale-100 opacity-100"
            leave-active-class="transition duration-75 ease-in"
            leave-from-class="transform scale-100 opacity-100"
            leave-to-class="transform scale-95 opacity-0"
          >
            <MenuItems
              class="shadow-lgfocus:outline-none absolute -ml-12 mt-10 flex w-48 origin-top-right flex-col gap-2 overflow-hidden rounded-lg bg-white"
            >
              <MenuItem
                v-for="c in chains"
                :key="c.name"
                class="p-2 text-lg text-black transition-all hover:bg-primary/25"
              >
                <button
                  class="flex items-center gap-1 text-left"
                  @click="walletStore.handlerChain(c)"
                >
                  <IconifyIcon :icon="c.icon" />
                  <span>
                    {{ c.name }}
                  </span>
                </button>
              </MenuItem>
            </MenuItems>
          </Transition>
        </Menu>
        <div class="-mb-1 -mr-4">
          <button
            class="tooltip tooltip-bottom"
            data-tip="Expand"
            @click="backToTab"
          >
            <IconifyIcon
              icon="material-symbols-light:back-to-tab-rounded"
              class="size-7 cursor-pointer"
            />
          </button>
        </div>
      </div>
      <div class="mb-5 flex gap-2 justify-self-center text-center">
        <div class="flex w-full flex-col items-center gap-1">
          <p class="text-sm">My Legend Wallet</p>
          <div
            v-if="myWallet"
            class="tooltip flex cursor-pointer items-center gap-1 text-white"
            data-tip="Copy Address"
            @click="useSocialShare(myWallet, '').copyToClipboard()"
          >
            <span class="font-extrabold">{{
              truncateHash(myWallet, 7, 10)
            }}</span>

            <IconifyIcon icon="icon-park-outline:copy" />
          </div>
          <div v-if="!myWallet" class="tooltip flex items-center gap-1">
            <ButtonComponent
              class="w-full"
              height="none"
              :is-loading="authStore.isConnectingWallet"
              @click="connectWallet"
            >
              Connect Wallet
            </ButtonComponent>
          </div>
        </div>
      </div>
      <main>
        <div class="flex flex-col items-center">
          <h3 class="flex items-center gap-2 text-2xl font-bold text-white">
            <span class="text-4xl font-extrabold">{{
              mainTokenFormatted?.toFixed(4)
            }}</span>
            {{ walletStore.tokenName }}
          </h3>
          <p class="text-lg">
            {{
              mainTokenFormatted &&
              walletStore.getMainTokenInUSD(mainTokenFormatted)
            }}
            USD
          </p>
        </div>
        <div class="my-5 flex justify-center gap-3">
          <div class="flex w-full flex-col items-center">
            <ButtonComponent class="w-full" height="none" @click="goToTokens">
              <IconifyIcon class="size-8 p-1.5" icon="ic:round-arrow-outward" />
              Send
            </ButtonComponent>
          </div>
        </div>
      </main>
      <div class="mt-7 flex justify-around">
        <ButtonComponent
          variant="ghost"
          class="w-28"
          :class="menuSection === 0 ? 'button-primary' : ''"
          @click="menuSection = 0"
          >Tokens</ButtonComponent
        >
        <ButtonComponent
          variant="ghost"
          class="w-24"
          :class="menuSection === 1 ? 'button-primary' : ''"
          @click="menuSection = 1"
        >
          NFTs
        </ButtonComponent>
        <ButtonComponent
          variant="ghost"
          class="w-28"
          :class="menuSection === 2 ? 'button-primary' : ''"
          @click="transactionHandler()"
        >
          Activity
        </ButtonComponent>
      </div>
      <template v-if="menuSection === 0">
        <div class="mt-2 h-64 overflow-y-auto scrollbar-none">
          <template v-if="tokensList.length">
            <template
              v-for="token in tokensList as TokenBalance[]"
              :key="token.blockHash"
            >
              <div
                class="my-3 flex h-[60px] justify-between rounded-md bg-darkestgray p-2 text-sm"
              >
                <div class="flex items-center gap-2">
                  <IconifyIcon
                    v-if="token.contractInfo?.symbol"
                    class="size-12"
                    :icon="coins[token.contractInfo.symbol]"
                  />
                  <div class="flex flex-col">
                    <p class="text-base font-bold leading-4">
                      {{ tokenParser(token.contractInfo?.name) }}
                    </p>
                    <span class="text-softGray">{{
                      tokenParser(token.contractInfo?.symbol)
                    }}</span>
                  </div>
                </div>
                <div class="flex flex-col items-end justify-center">
                  <p class="text-lg font-bold leading-4">
                    {{ parseFloat(getTokenBalance(token).toFixed(4)) }}
                  </p>
                  <p class="text-softGray">
                    {{
                      walletStore.getAmountInUSD(token, getTokenBalance(token))
                    }}
                  </p>
                </div>
              </div>
            </template>
          </template>
        </div>
        <section class="my-2 -mt-1 w-full overflow-hidden rounded-md">
          <PromoBanner
            ad-client="ca-pub-2471374979359328"
            ad-slot="4200649249"
            width="350px"
            height="50px"
            rounded="md"
          />
        </section>
      </template>
      <template v-if="menuSection === 1">
        <div class="mt-2 h-64 overflow-y-auto scrollbar-none">
          <template v-if="nftList?.length">
            <div class="grid grid-cols-3 gap-1">
              <template v-for="nft in nftList" :key="nft.blockHash">
                <template v-if="nft.tokenMetadata">
                  <img
                    :src="nft.tokenMetadata.image"
                    width="80"
                    class="size-28 cursor-pointer rounded-md object-cover"
                    :alt="nft.tokenMetadata.name"
                    @click="goToNFT(nft)"
                  />
                </template>
              </template>
            </div>
          </template>
          <template v-else>
            <div class="grid size-full place-content-center text-softGray">
              There are no NFTs yet
            </div>
          </template>
        </div>
        <ButtonComponent class="my-3 w-full" @click="goToNFTs">
          See more
        </ButtonComponent>
      </template>
      <template v-if="menuSection === 2">
        <div class="mt-4 h-60 overflow-y-auto overflow-x-clip">
          <template v-if="transactionsList?.length">
            <template
              v-for="transaction in transactionsList.slice(0, 10)"
              :key="transaction.blockHash"
            >
              <template
                v-if="transactionValidate(transaction as Transaction, coins)"
              >
                <div class="-mb-2 ml-2 text-xs">
                  {{ formatTimestamp(transaction.timestamp) }}
                </div>
                <div
                  class="my-2 flex cursor-pointer justify-between rounded-md bg-darkestgray p-2 text-sm"
                  @click="goToTransaction(transaction)"
                >
                  <div class="flex items-center gap-2">
                    <template
                      v-if="
                        transaction.transfers?.length &&
                        transaction.transfers?.[0].contractInfo &&
                        (transaction.transfers?.[0].contractType ===
                          'ERC1155' ||
                          transaction.transfers?.[0].contractType === 'ERC721')
                      "
                    >
                      <img
                        src="/images/nft-icon.webp"
                        class="size-8 rounded-full object-cover"
                        alt="nft-icon"
                      />
                    </template>
                    <template
                      v-else-if="
                        transaction.transfers?.length &&
                        transaction.transfers?.[0].contractInfo &&
                        transaction.transfers?.[0].contractType === 'NATIVE'
                      "
                    >
                      <IconifyIcon
                        class="size-8"
                        :icon="coins[walletStore.tokenName]"
                      />
                    </template>
                    <template
                      v-else-if="
                        transaction.transfers?.length &&
                        transaction.transfers?.[0].contractInfo
                      "
                    >
                      <IconifyIcon
                        class="size-8"
                        :icon="
                          coins[transaction.transfers?.[0].contractInfo.symbol]
                        "
                      />
                    </template>
                    <div
                      v-if="
                        transaction.transfers?.[0].contractType === 'NATIVE'
                      "
                    >
                      <h4>
                        {{ walletStore.tokenName }}
                      </h4>
                      <p class="text-softGray">
                        {{ walletStore.tokenName }}
                      </p>
                    </div>
                    <div
                      v-else-if="
                        transaction.transfers?.[0].contractType === 'ERC1155' ||
                        transaction.transfers?.[0].contractType === 'ERC721'
                      "
                    >
                      <h4>
                        {{
                          transaction.transfers?.[0].tokenIds &&
                          transaction.transfers?.[0].tokenMetadata &&
                          transaction.transfers?.[0].tokenMetadata![
                            transaction.transfers?.[0].tokenIds![0]
                          ]?.name
                        }}
                      </h4>
                      <p class="text-softGray">
                        {{
                          tokenParser(
                            transaction.transfers?.[0].contractInfo?.name,
                          )
                        }}
                      </p>
                    </div>
                    <div v-else>
                      <h4>
                        {{
                          tokenParser(
                            transaction.transfers?.[0].contractInfo?.name,
                          )
                        }}
                      </h4>
                      <p class="text-softGray">
                        {{
                          tokenParser(
                            transaction.transfers?.[0].contractInfo?.symbol,
                          )
                        }}
                      </p>
                    </div>
                  </div>
                  <div class="flex gap-2">
                    <template
                      v-if="
                        transaction.transfers?.[0].contractType ===
                        ContractType.NATIVE
                      "
                    >
                      <div class="flex flex-col items-end">
                        <div class="flex">
                          <p>
                            {{
                              transaction.transfers?.at(-1) &&
                              parseFloat(
                                getTransferBalance(
                                  transaction.transfers?.at(-1)!,
                                ).toFixed(4),
                              )
                            }}
                            <span>{{
                              tokenParser(
                                transaction.transfers?.[0].contractInfo?.symbol,
                              )
                            }}</span>
                          </p>
                        </div>
                        <p class="text-softGray">
                          {{
                            transaction.transfers?.at(-1) &&
                            walletStore.getAmountInUSD(
                              transaction.transfers?.at(-1)!,
                              getTransferBalance(
                                transaction.transfers?.at(-1)!,
                              ),
                            )
                          }}
                        </p>
                      </div>
                    </template>
                    <template v-else>
                      <div class="flex flex-col items-end">
                        <div class="flex">
                          <p>
                            {{
                              transaction.transfers?.[0] &&
                              parseFloat(
                                getTransferBalance(
                                  transaction.transfers?.[0],
                                ).toFixed(4),
                              )
                            }}
                            <span>{{
                              tokenParser(
                                transaction.transfers?.[0].contractInfo?.symbol,
                              )
                            }}</span>
                          </p>
                        </div>
                        <p class="text-softGray">
                          {{
                            transaction.transfers?.[0] &&
                            walletStore.getAmountInUSD(
                              transaction.transfers?.[0],
                              getTransferBalance(transaction.transfers?.[0]),
                            )
                          }}
                        </p>
                      </div>
                    </template>
                    <template
                      v-if="
                        transaction.transfers?.[0].transferType === 'RECEIVE'
                      "
                    >
                      <div class="flex items-center gap-3">
                        <IconifyIcon
                          class="size-8 rounded-full border-2 border-green-600 p-1 text-green-600"
                          icon="ic:round-arrow-downward"
                        />
                      </div>
                      <!-- {{ transfer }} -->
                    </template>
                    <template
                      v-if="
                        transaction.transfers?.at(-1)?.transferType === 'SEND'
                      "
                    >
                      <div class="flex items-center gap-3">
                        <IconifyIcon
                          class="size-8 rounded-full border-2 border-green-600 p-1 text-green-600"
                          icon="ic:round-arrow-outward"
                        />
                      </div>
                      <!-- {{ transfer }} -->
                    </template>
                  </div>
                </div>
              </template>
            </template>
          </template>
          <template v-else
            ><div class="grid size-full place-content-center text-softGray">
              You have no transactions
            </div>
          </template>
        </div>

        <ButtonComponent class="my-5 w-full" @click="goToActivity">
          See more
        </ButtonComponent>
      </template>
    </section>
    <div
      class="mx-auto -mb-4 w-1/3 rounded-t-full border-b-4 border-softGray"
    ></div>
  </GenericModal>
  <GenericModal v-model="showComingSoon" size="sm">
    <p
      class="flex h-24 items-start justify-center text-pretty text-xl font-bold italic"
    >
      Soon you will be able to enjoy your virtual wallet within Legendaryum
    </p>
  </GenericModal>
</template>
