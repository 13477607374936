<script setup lang="ts">
import { computed } from 'vue';

interface Props {
  modelValue: string;
  error: boolean;
  title: string;
  inputType: string;
  name?: string;
  errorMessage?: string;
  placeholder?: string;
}

const emits = defineEmits<{
  (e: 'update:modelValue', modelValue: string): string;
  (e: 'update', modelValue: string): string;
  (e: 'clearErrors'): void;
}>();

const props = defineProps<Props>();

const value = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emits('update:modelValue', value);
    emits('update', value);
    emits('clearErrors');
  },
});
</script>

<template>
  <div class="relative">
    <label :for="name" class="mb-1 block text-sm">{{ title }}</label>
    <input
      v-model="value"
      :type="inputType"
      class="h-9 w-full rounded-md border-2 p-4"
      :name="name"
      :class="{
        'border-2 bg-white text-black': !error,
        'border-2 border-red-500 bg-white text-red-700': error,
      }"
      :placeholder="placeholder"
      required
      maxlength="50"
    />
    <div>
      <p v-if="error" class="top-15 absolute right-0 text-sm text-red-500">
        {{ errorMessage }}
      </p>
    </div>
  </div>
</template>
